import React from 'react'
import Layout from './Layout';

const Vision = () => {
  return (
    <>
      <Layout />
      <body className='bodyreg'>
        <div id='registrations'>
          <center>  <h1>Registrations</h1></center>


          <hr className='dashed' />
          <ul>
            <li>
              <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
              We are registered under the Society Registration Act 1860
            </li>
            <li>
              <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>  We are registered under the Public Trust Act of 1950</li>
            <ul><b>We also have:</b>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>	Reg. 80G is also applicable to donations made to us.</li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. 12A is also applicable to donations made to us.</li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. 12A is also applicable to donations made to us.
              </li>

              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. CSR is also applicable to donations made to us.
              </li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. Niti Aayog- NGO Darpan is also applicable to donations made to us.
              </li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. E-Anudan is also applicable to donations made to us.
              </li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. ISO 9001 : 2015
              </li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. FCRA No under Proccess
              </li>
              <li>
                <svg class="m-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="goldenrod" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg> Reg. 35A No under Proccess
              </li>
            </ul >

          </ul >


        </div >
      </body>
    </>
  )
}

export default Vision;