import React from 'react'
import logo from './img/ngologo.png';
import { useState } from "react";
import { Link } from 'react-router-dom';


const Header = () => {
    const [isResponsive, setIsResponsive] = useState(false);

    const toggleResponsive = () => {
        setIsResponsive(prevState => !prevState);
    };
    return (
        <div>

            <head>
                <link rel="stylesheet" href="./style.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
            </head>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" id="navbar">

                <div className="container-fluid">
                    <span className="nav-item-logo"><img src={logo} /></span>

                    <button className="navbar-toggler" type="button" onClick={toggleResponsive}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`collapse navbar-collapse ${isResponsive ? 'show' : ''}`} id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto">
                            <li class="nav-item ">
                                <Link class="nav-link" to="/">Home</Link>
                            </li>
                            <li class="nav-item ">
                                <Link class="nav-link" to="/about"> About us</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/programmes">    Our Programmes </Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/gallery" >   Gallery   </Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" to="/vision">Registrations</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" to="/appeal">  Appeal</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" to="/trustees">  Trustees</Link>
                            </li>
                            <li class="nav-item">
                                <Link className="nav-link" to="/contact">     Contact</Link>
                            </li>
                            <button className="btndonate"><Link to='/donate'>Donate</Link></button>
                            {/* <li >  <a className="nav-link" href="/login"> <lord-icon
                                src="https://cdn.lordicon.com/bgebyztw.json"
                                trigger="in"
                                delay="1500"
                                stroke="bold"
                                state="in-reveal"
                                colors="primary:#109121,secondary:#109121"
                                class="iconlogin"
                            >
                            </lord-icon>  </a></li> */}
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;