import React from 'react'
import Layout from './Layout';

const Trustees = () => {
    return (


        <>
            <Layout />
            <div id='trustees' class='container'>
                <center> <h1>Trustees</h1></center>
                <div className='d-flex'>
                    <div className='row'>
                        <div className='col'>
                            <div class="user">
                                <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mr. V V Mukane</span>
                            </div>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mr. J H Mukane</span>
                            </div>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mrs. S S Pawar</span>
                            </div>
                        </div>
                        <div className='col'>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mr. A V Zole</span>
                            </div>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mrs. V A Zole</span>
                            </div>

                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mrs. V V Mukane</span>
                            </div>

                        </div>
                        <div className='col'>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mr. S H Pawar</span>
                            </div>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mrs. S R Wagh</span>
                            </div>
                            <div class="user">
                                <lord-icon
                                    src="https://cdn.lordicon.com/bgebyztw.json"
                                    trigger="in"
                                    delay="1500"
                                    stroke="bold"
                                    state="in-reveal"
                                    colors="primary:#109121,secondary:#109121"
                                    class="icon"
                                >
                                </lord-icon>
                                <br />
                                <span>Mr. V J Gore</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Trustees;