import React from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { adminlogin } from "./adminSlice";
import Layout from './Layout';

const Login = () => {
    const [isUnlocked, setIsUnlocked] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const myDispatch = useDispatch();
    const myNav = useNavigate();
    const submitHandle = (e) => {

        e.preventDefault()
        let url = `http://localhost:4000/adminuser/?email=${email}`;
        axios.get(url).then((res) => {
            if (res.data.length === 1) {
                if (res.data[0].password === password) {

                    let adminname = res.data[0].name;
                    let adminid = res.data[0].id;

                    myDispatch(adminlogin({ "adname": adminname, "adid": adminid }))

                    myNav("/admindashboard");
                }
                else {
                    alert("Password dose not Match!!!");
                }
            }
            else {
                alert("Invalid Email!!!");
            }
        });
    }
    const unlock = (e) => {
        e.preventDefault();
        setIsUnlocked((prevIsUnlocked) => !prevIsUnlocked);
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    return (
        <>
            <Layout />
            <center>

                <div className='loginbox'>
                    <br />  <br />  <br />
                    <div class="container" id="container">
                        <div className="form-container sign-in">
                            <form>
                                <h1 class="h1 heading">LOGIN  FORM</h1>
                                <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                                <br />
                                <input type="password" name="password" placeholder="Password" value={password} id="myInput" onChange={(e) => { setPassword(e.target.value) }} required />
                                {/* <div className="contain"> */}
                                <button id='eye' className={`lock ${isUnlocked ? 'unlocked' : ''}`} onClick={unlock} ><script src="https://cdn.lordicon.com/lordicon.js"></script>
                                    <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                    <lord-icon
                                        src="https://cdn.lordicon.com/hbtheitu.json"
                                        trigger="hover"
                                        class="iconloginn" id="chasma">
                                    </lord-icon></button>
                                {/* </div> */}

                                <br />
                                <button className="btn" onClick={submitHandle}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </center>

        </>
    )
}

export default Login;